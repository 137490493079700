<template>
  <Modal :style="computedWindowSize"
         :visible="visible"
         class="p-fluid"
         @close="close">
    <template v-slot:header>
      <div class="p-dialog-title">Adding files</div>
    </template>
    <Spinner v-show="dataIsSending"></Spinner>
    <div v-show="!dataIsSending" class="p-formgrid p-grid" >
      <div class="p-field p-col-12 p-sm-12 p-md-12 p-lg-12">
        <ModifiedFileUpload
            :clearAttachments="!visible"
            name="demo[]"
            @select="onFileSelect"
            chooseLabel="Add attachment"
            uploadLabel="Upload11!!"
            cancelLabel="Clear"
            :maxFileSize="5242880"
            invalidFileSizeMessage="File size should be smaller (Max: 5mb)">
          <template #empty>
            <div class="p-text-center">
              <p>Drag and drop files to here to upload.</p>
            </div>
          </template>
        </ModifiedFileUpload>
      </div>
    </div>
    <template #footer>
      <Button :label="$t('Cancel')" icon="pi pi-times" class="p-button-text" @click="close"/>
      <Button :disabled="!attachments?.length" :label="$t('Save')" icon="pi pi-check" class="p-button-text" @click="saveFiles"/>
    </template>
  </Modal>
</template>
<script>
import httpClient from '@/services/http.services'
// import httpMixins from "@/mixins/httpMixins";
import ModifiedFileUpload from "@/components/ModifiedFileUpload";
import formatMixins from "@/mixins/formatMixins";
import settings from "@/settings";
import showErrorsMixins from "@/mixins/showErrorsMixins";

export default {
  mixins: [ formatMixins, showErrorsMixins ],
  components: { ModifiedFileUpload},
  emits: ['close', 'update-item'],
  name: 'FilesModal',
  props: {
    orderId: [Number, null],
    visible: Boolean,
  },
  data() {
    return {
      dataIsSending: false,
      submitted: false,
      attachments: [],
      // file: null,
      clearAttachments: false,
      settings
    }
  },
  watch: {
    visible() {
      if (!this.visible) {
        this.submitted = false

        this.attachFiles = true
        this.attachments = []

        this.clearAttachments = true
      } else {
        this.clearAttachments = false
      }
    },
  },
  methods: {
    onFileSelect(data) {
      this.attachments = data.files || []
      // console.log(data.files)
    },
    async saveFiles() {
      this.submitted = true
      this.dataIsSending = true

      let formData = new FormData();
      formData.append('orderId', this.orderId)

      const attachmentsLength = this.attachments?.length
      if (attachmentsLength) {
        for (let i = 0; i < attachmentsLength; i++)
          formData.append(i, this.attachments[i])
      }

      try {
        const { status, data } = await httpClient.post(`order/save-files`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        })
        if (status === 201 && data?.success) {
          this.$emit('update-item', this.orderId, true) //True === sent socket, for Order page
          this.$toast.add({severity:'success', detail: this.$t('Data updated'), life: settings.toastLife});
          this.attachments = []
          this.clearAttachments = true
          this.close()
        } else if (data?.error) {
          this.$toast.add({severity:'error', summary: data.error.summary, detail: data.error.detail, life: settings.toastLife});
        }
      } catch(err) {
        this.showError(err)
      } finally {
        this.dataIsSending = false
      }
    },
    close() {
      this.$emit('close')
      this.submitted = false
    },
  },
  computed: {
    computedWindowSize() {
      if (this.$store.state.mobileLayout) {
        return {position: 'absolute', top: 0, width: '95%'}
      } else if (!this.$store.state.mobileLayout && this.$store.state.tabletLayout) {
        return {position: 'absolute', top: 0, width: '630px'}
      } else {
        return {position: 'absolute', top: 0, width: '980px'}
      }
    },
  },

}
</script>

<style scoped lang="scss">
//.test {
//  color: rgba(46, 199, 0, 0.18);
//}
.removed-attachment {
  text-decoration: line-through;
}
</style>