<template>
  <Modal :style="computedWindowSize"
         :visible="visible"
         class="p-fluid"
         @close="close">
    <template v-slot:header>
      <div class="p-dialog-title">Return cancellation</div>
    </template>
    <Spinner v-if="isLoading" :isSmall="true"></Spinner>
    <template v-else-if="items?.length">
      <div class="table-wrapper">
        <table class="p-datatable p-datatable-striped p-datatable-sm p-datatable-hoverable-rows">
          <thead class="p-datatable-thead">
          <template></template>
          <tr>
<!--            <th></th>-->
            <th>{{ $t('Code') }}</th>
            <th>{{ $t('Name') }}</th>
            <th>{{ $t('Supplier') }}</th>
            <th>{{ $t('Qty') }}</th>
            <th>{{ $t('Type') }}</th>
            <th>{{ $t('Reason') }}</th>
            <th></th>
            <th>{{ $t('Created') }}</th>
            <th>Qty to cancel</th>
          </tr>
          </thead>
          <tbody class="p-datatable-tbody">
            <tr v-for="item of items" :key="item.id">
<!--              <td>-->
<!--                <Checkbox v-if="!isItemAdded(item.id)" :disabled="isLoading" :value="item.id" v-model="selectedItems"/>-->
<!--              </td>-->
              <td>
                <span v-if="item.position_data?.code">{{ item.position_data.code }}</span>
                <i v-else class="ti-minus"></i>
              </td>
              <td>
                <span v-if="item.position_data?.name">{{ item.position_data.name }}</span>
                <i v-else class="ti-minus"></i>
              </td>
              <td>
                <span v-if="item.supplier">{{ item.supplier.company_name }}</span>
                <i v-else class="ti-minus"></i>
              </td>
              <td>
                <span>{{ +item.qty - +item.fixed_qty }}</span>
              </td>
              <td>
                <span v-if="item.type">{{ ReducedPositionTypes.find(type => type.id === item.type)?.label[$i18n.locale] }}</span>
                <i v-else class="ti-minus"></i>
              </td>
              <td>
                <span v-if="item.reason">{{ ReducedPositionReasons.find(reason => reason.id === item.reason)?.label[$i18n.locale] }}</span>
                <i v-else class="ti-minus"></i>
              </td>
              <td>
                <span v-if="item.comment" @click.prevent href="" v-tooltip.top="item.comment"><i class="ti-comment-alt" ></i></span>
              </td>
              <td>
                <template v-if="item.created_at || item.creator">
                  <span v-if="item.created_at">{{ formatDate(item.created_at) }}, </span>
                  <span v-if="item.creator" class="table-date-line">{{ item.creator.first_name }} {{ item.creator.last_name }}</span>
                </template>
                <i v-else class="ti-minus"></i>
              </td>
              <!--            <td style="text-align: right">-->
              <td>
                <!--              <Button :label="$t('Select')" class="p-button-raised" style="width: auto; margin: 0 auto;"></Button>-->
                <div class="p-float-label">
                  <!--                @input="qtyOnInput($event, item)"-->
                  <InputNumber id="return-cancellation-qty-modal"
                               :inputClass="{'p-invalid' : submitted && (item.qtyToCancel > (item.qty - item.fixed_qty))}"
                               :locale="computedNumberInputLocale"
                               @input="qtyOnInput($event, item)"
                               :min="0"
                               :max="item.qty - item.fixed_qty"
                               v-model="item.qtyToCancel"
                               showButtons
                               style="width: 120px;"
                               autocomplete="off"/>
                  <!--          <label for="change-request-qty-modal-qty">{{ item.transactionType === 1 ? 'Add qty' : 'Decrease qty'}}<span class="warning-color">*</span></label>-->
                  <!--                <label for="change-request-qty-modal-qty">{{ $t('Qty') }}<span class="warning-color">*</span></label>-->
                  <!--          </div>-->
                </div>
              </td>
            </tr>
          <tr>
            <td colspan="3">
              <div class="p-d-flex p-ai-center">
                <Checkbox v-model="changeQtyInOrder" :binary="true" id="delete-from-order-checkbox"/>
                <label for="delete-from-order-checkbox" class="p-ml-1 pointer">Change quantity in order</label>
              </div>
            </td>
            <td colspan="5" style="text-align: right">New order position quantity:</td>
            <td>
              <InputNumber id="change-request-qty-modal-new-qty"
                           :locale="computedNumberInputLocale"
                           disabled
                           style="width: 120px;"
                           :modelValue="computedNewQty"
                           autocomplete="off"/>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
<!--      <div class="p-d-flex p-ai-center p-flex-wrap p-jc-between p-mt-4">-->
<!--&lt;!&ndash;        <div>&ndash;&gt;-->
<!--          <div class="p-d-flex p-ai-center">-->
<!--            <Checkbox v-model="changeQtyInOrder" :binary="true" id="delete-from-order-checkbox"/>-->
<!--            <label for="delete-from-order-checkbox" class="p-ml-1 pointer">Change quantity in order</label>-->
<!--          </div>-->
<!--&lt;!&ndash;        </div>&ndash;&gt;-->
<!--&lt;!&ndash;        <div>&ndash;&gt;-->
<!--&lt;!&ndash;          <span class="p-mr-1">New position quantity:</span>&ndash;&gt;-->
<!--&lt;!&ndash;          <InputNumber id="change-request-qty-modal-new-qty"&ndash;&gt;-->
<!--&lt;!&ndash;                           :locale="computedNumberInputLocale"&ndash;&gt;-->
<!--&lt;!&ndash;                           disabled&ndash;&gt;-->
<!--&lt;!&ndash;                           style="width: 120px;"&ndash;&gt;-->
<!--&lt;!&ndash;                           :modelValue="newQty"&ndash;&gt;-->
<!--&lt;!&ndash;                           autocomplete="off"/>&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;          <label for="change-request-qty-modal-new-qty">New qty</label>&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;        </div>&ndash;&gt;-->
<!--      </div>-->
    </template>
    <template #footer>
      <Button :label="$t('Close')" icon="pi pi-times" class="p-button-text" @click="close"/>
<!--      <Button :label="$t('Save')" :disabled="qtyToCancelEmpty" icon="pi pi-check" class="p-button-text" @click="saveItem"/>-->
      <Button :label="$t('Save')" :disabled="isLoading || disableSaveButton" icon="pi pi-check" class="p-button-text" @click="saveItem"/>
    </template>
  </Modal>
</template>

<script>
import settings from '@/settings'
import constants from '@/constants'
import httpClient from "@/services/http.services";
import formatMixins from '@/mixins/formatMixins'
// import AddButton from '@/components/DataTable/AddButton'
// import ReduceButton from '@/components/DataTable/ReduceButton'
import ReducedPositionReasons from '@/translations/ReducedPositionReasons'
import ReducedPositionTypes from "@/translations/ReducedPositionTypes";
import datatableMixins from '@/mixins/datatableMixins'
import httpMixins from "@/mixins/httpMixins";

export default {
  mixins: [ formatMixins, datatableMixins, httpMixins ],
  // components: { AddButton, ReduceButton  },
  emits: ['close', 'update-items', 'add-position', 'remove-position', 'items-loaded', 'modal-data-on-clear'],
  name: 'ReturnCancellationModal',
  props: {
    orderPosition: {
      type: [Object, null],
      default: null
    },
    visible: Boolean,
  },
  data() {
    return {
      // newQty: 0,
      changeQtyInOrder: true,
      submitted: false,
      dataIsSending: false,
      items: null,
      // fieldsExpand: 'position_data,order,car,creator,supplier,purchaseInvoicePosition,purchase_invoice_number',
      fieldsExpand: 'position_data,creator,supplier',
      apiLink: 'reduced-position/get-request-reduced-positions',
      constants,
      ReducedPositionReasons,
      ReducedPositionTypes,
      settings,
    }
  },
  watch: {
    // position(value) {
      // this.positionData = { ...value }
      // this.maxQtyToReturn = value.maxQtyToReturn
      // this.maxQtyToReturn = value.maxQtyToReturn
      // this.newQty = value.qty

      //Commented is previous version
      // if ((value.ordered_qty || +value.ordered_qty === 0)) {
      //   this.maxQtyToReturn = +value.ordered_qty - (value.reduced_qty ?? 0)
      //   this.qtyToReturn = this.maxQtyToReturn ? 1 : 0
      // }
      // this.maxQtyToReturn = value.originalQty ? +value.originalQty - (value.reduced_qty ?? 0) : 0
      // this.maxQtyToReturn = value.ordered_qty ? +value.ordered_qty - (value.reduced_qty ?? 0) : 0
      // this.qtyToReturn = this.maxQtyToReturn ? 1 : 0
      // this.qtyToReturn = 0

      // this.maxQtyToReturn = +value.qty
    // },
    visible(boolean) {
      if (boolean) {
        this.getItems()
      }
    }
  },
  methods: {
    // toggleChangeQtyInOrderCheckbox() {
    //   if (this.changeQtyInOrder) {
    //     this.newQty = 100
    //   } else {
    //     this.newQty = this.position.qty
    //   }
    // },
    qtyOnInput(event, item) {
      let value = event.value
      if (value === '-') {
        value = 0
      }
      console.log(!value)
      if (!value || +value < 0) {
        value = 0
      }
      if (value > item.qty - item.fixed_qty) {
        value = item.qty - item.fixed_qty
      }
      console.log(value)

      item.qtyToCancel = value ? +value : 0
      // this.newQty = this.item.qty - value
    },
    // qtyOnInput($event, item) {
    //   if (!item) return
    //   let value = event.value
    //   // console.log(value)
    //   // if (value === '-') {
    //   //   value = 0
    //   //   // return
    //   // }
    //   if (value === '-' || !value || +value < 0) {
    //     value = 0
    //   }
    //   this.qtyToCancel = +value
    //
    //   // if (this.item.transactionType === 2 && (value > this.maxQtyToDecrease)) {
    //   //   value = this.maxQtyToDecrease
    //   // } else if (this.item.transactionType === 1 && (this.item.qty + value > 99999)) {
    //   //   value = 99999 - this.item.qty
    //   //   // console.log(value)
    //   // }
    //   //
    //   // this.qty = +value
    //   // this.newQty = this.item.transactionType === 1 ? this.item.qty + value : this.item.qty - value
    // },
    async saveItem() {
      console.log('save item')
      if (!this.items?.length) return
      this.submitted = true
      // if (!this.qty ||
      //     (this.maxQtyToDecrease && this.qty > this.maxQtyToDecrease))  {
      //   return false
      // }

      const cancelledReturnsData = [];

      this.items.filter(i => i.qtyToCancel && +i.qtyToCancel).forEach(i => {
        // if (!i.qtyToCancel) return false
        cancelledReturnsData.push({id: i.id, qtyToCancel: i.qtyToCancel })
      })
      console.log(cancelledReturnsData.length)
      // console.log(cancelledReturnsData)
      this.isLoading = true

      try {
        const { status, data } = await httpClient.post(`reduced-position/cancel`, cancelledReturnsData)
        console.log(status, data)
        // if (status === 200 && data && (data.success || data.max_qty_error)) {
        //   let sendSocket = false
        //   let updateItem = true
        //   if (data.success) {
        //     sendSocket = true
        //     this.$toast.add({severity: 'success', detail: this.$t('Data updated'), life: settings.toastLife});
        //     this.close()
        //   } else if (data.max_qty_error) {
        //     this.maxQtyToDecrease = data.maxQtyToDecrease || +data.maxQtyToDecrease === 0 ? +data.maxQtyToDecrease : 0
        //     if (this.maxQtyToDecrease === 0) {
        //       updateItem = false
        //       this.$toast.add({severity: 'success', detail: this.$t('Data updated'), life: settings.toastLife});
        //       this.close()
        //     } else {
        //       this.$toast.add({severity:'error', detail: 'Maximum qty to decrease: ' + data.maxQtyToDecrease, life: settings.toastLife})
        //     }
        //   }
        //   this.$emit('actualize-request-data', {serverPositionData : data, orderPosition: this.item, action: 'change-qty', sendSocket, updateItem});
        // } else if (data?.error) {
        //   console.log(data.error)
        //   this.$toast.add({severity:'error', summary: data.error.summary, detail: data.error.detail, life: settings.toastLife});
        // } else {
        //   this.showUnknownError()
        // }
      } catch(err) {
        this.showError(err)
      } finally {
        this.isLoading = false
      }
    },
    async getItems() {
      if (!this.orderPosition?.id) return
      this.isLoading = true
      // this.dataTableIsLoading = true
      try {
        // const { status, data } = await httpClient({
        //   url: this.apiLink,
        //   method: 'post',
        //   params: {
            // page: this.pagination.currentPage,
            // 'per-page': this.pagination.perPage,
            // sort: this.sort.direction + this.sort.column,
            // sortColumn: this.sort.column,
            // sortDirection: this.sort.direction,
            // expand: this.fieldsExpand,
          // },
        // })
        const { status, data } = await httpClient.post(
            'reduced-position/get-request-reduced-positions?expand=position_data,creator,supplier',
            { positionId: this.orderPosition.id })
        console.log(status, data)
        this.items = data
        this.items?.forEach(i => i.qtyToCancel = 0)

        // if (status === 200 && data) {
        //   this.pagination.totalCount = +headers['x-pagination-total-count']
        //   this.pagination.pageCount = +headers['x-pagination-page-count']
        //   // this.pagination.currentPage = +headers['x-pagination-current-page']
        //   // this.pagination.perPage = +headers['x-pagination-per-page']
        //   this.items = data
        //
        //   const arrayIsEmpty = !data?.length
        //
        //   if (arrayIsEmpty) {
        //     this.pagination.pageCount = 1
        //     // this.pagination.currentPage = 1
        //     // this.$emit('positions-loaded')
        //   } else {
        //     // this.$emit('disable-request-button', false)
        //   }
        //   this.$emit('items-loaded', arrayIsEmpty)
        // }
      } catch(err) {
        this.showError(err)
      } finally {
        this.isLoading = false
        // this.dataTableIsLoading = false
      }
    },
    close() {
      this.$emit('close')
    }
  },
  computed: {
    computedNewQty() {
      if (!this.items?.length || !this.orderPosition) return 0

      if (this.changeQtyInOrder) {
        const itemsTotalQtyToCancel = this.items?.reduce((acc, i) => acc + (i.qtyToCancel ?? 0), 0)
        return this.orderPosition.qty + itemsTotalQtyToCancel
      } else {
        return this.orderPosition.qty
      }
      // item.qtyToCancel
      // return this.changeQtyInOrder ? this.orderPosition.qty + this.qty : this.orderPosition.qty
    },
    disableSaveButton() {
      return !this.items?.find(i => i.qtyToCancel && +i.qtyToCancel)
    },
    // qtyToCancelEmpty() {
    //   return !this.items?.find(i => i.qtyToCancel)
    // },
    actionButtonsCount() {
      // if (this.$store.state.user.role === constants.userRoles.superAdmin || this.$store.state.user.role === constants.userRoles.admin) {
      //   return 2
      // } else {
      //   return 1
      // }
      return 1
    },
    computedWindowSize() {
      if (this.$store.state.mobileLayout) {
        return {position: 'absolute', top: '15px', width: '95%', marginTop: '10px'}
      } else if (!this.$store.state.mobileLayout && this.$store.state.tabletLayout) {
        return {position: 'absolute', top: '15px', width: '85%', marginTop: '25px'}
      } else {
        return {position: 'absolute', top: '15px', width: '80%', maxWidth: '1100px', marginTop: '50px'}
      }
    },
  },

}
</script>

<style scoped lang="scss">
.table-wrapper {
  overflow-y: auto;
}

.p-datatable {
  width: 100%;
  border-collapse: collapse;
}
</style>