<template>
<!--  <transition name="p-contextmenusub" @enter="onEnter">-->
  <transition name="p-contextmenusub">
    <Spinner v-if="showSpinner" isSmall :spinnerStyle="'width: 48px; height: 48px; margin: 25px 15px'"></Spinner>
    <ul ref="container" :class="containerClass" role="menu" v-else-if="root ? true : parentActive">
<!--      <div v-if="notOrderedStates?.length" class="states-title">Not ordered:</div>-->
<!--      <template v-for="(item, i) of notOrderedStates" :key="item.label + i.toString()">-->
<!--        <li role="none" :class="getItemClass(item)" :style="item.style" v-if="visible(item) && !item.separator"-->
<!--            @mouseenter="onItemMouseEnter($event, item)">-->
<!--          <router-link v-if="item.to && !item.disabled" :to="item.to" custom v-slot="{navigate, href}">-->
<!--            <a :href="href" @click="onItemClick($event, item, navigate)" :class="getLinkClass(item)" v-ripple role="menuitem">-->
<!--              <span :class="['p-menuitem-icon request-state', item.classData]"></span>-->
<!--              <span class="p-menuitem-text">{{item.label[$i18n.locale]}}</span>-->
<!--            </a>-->
<!--          </router-link>-->
<!--          <a v-else :href="item.url" :class="getLinkClass(item)" :target="item.target" @click="onItemClick($event, item)" v-ripple-->
<!--             :aria-haspopup="item.items != null" :aria-expanded="item === activeItem" role="menuitem" :tabindex="item.disabled ? null : '0'">-->
<!--            <span :class="['p-menuitem-icon request-state', item.classData]"></span>-->
<!--            <span class="p-menuitem-text">{{item.label[$i18n.locale]}}</span>-->
<!--            <span class="p-submenu-icon pi pi-angle-right" v-if="item.items"></span>-->
<!--          </a>-->
<!--          <sub-menu :model="item.items" v-if="visible(item) && item.items" :key="item.label + '_sub_'"-->
<!--                    @leaf-click="onLeafClick()" :parentActive="item === activeItem" />-->
<!--        </li>-->
<!--        <li :class="['p-menu-separator', item.class]" :style="item.style" v-if="visible(item) && item.separator" :key="'separator' + i.toString()" role="separator"></li>-->
<!--      </template>-->

<!--      <hr class="p-m-2" v-if="notOrderedStates?.length">-->

<!--      <div v-if="orderedStates?.length" class="states-title menu-bg-color">Ordered:</div>-->
      <template v-for="(item, i) of model" :key="item.label + i.toString()">
        <li role="none" :class="getItemClass(item)" :style="item.style" v-if="visible(item) && !item.separator"
            @mouseenter="onItemMouseEnter($event, item)">
          <a :class="getLinkClass(item)" class="p-d-flex p-ai-center p-jc-between" @click="onItemClick($event, item)" v-ripple
             :aria-haspopup="item.items != null" :aria-expanded="item === activeItem" role="menuitem" :tabindex="item.disabled ? null : '0'">
              <span class="p-menuitem-text">{{item.label[$i18n.locale]}}</span>
              <i :class="item.icon"></i>
<!--            <span class="p-submenu-icon pi pi-angle-right" v-if="item.items"></span>-->
          </a>
<!--          <sub-menu  v-if="visible(item) && item.items" :key="item.label + '_sub_'"-->
<!--                    @leaf-click="onLeafClick()" :parentActive="item === activeItem" />-->
        </li>
        <li :class="['p-menu-separator', item.class]" :style="item.style" v-if="visible(item) && item.separator" :key="'separator' + i.toString()" role="separator"></li>
      </template>
    </ul>
  </transition>
</template>

<script>
// import {DomHandler} from 'primevue/utils';
import Ripple from 'primevue/ripple';
export default {
  emits: ['leaf-click'],
  name: 'RequestActionsMenuSub',
  props: {
    model: {
      type: Array,
      default: null
    },
    showSpinner: {
      type: Boolean,
      default: false,
    },
    root: {
      type: Boolean,
      default: false
    },
    parentActive: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    parentActive(newValue) {
      if (!newValue) {
        this.activeItem = null;
      }
    }
  },
  data() {
    return {
      activeItem: null,
    }
  },
  methods: {
    onItemMouseEnter(event, item) {
      if (item.disabled) {
        event.preventDefault();
        return;
      }
      this.activeItem = item;
    },
    onItemClick(event, item) {
      if (item.disabled) {
        event.preventDefault();
        return;
      }
      this.onLeafClick(item);
      // if (item.command) {
      //   item.command({
      //     originalEvent: event,
      //     item: item
      //   });
      // }
      // if (item.items) {
      //   if (this.activeItem && item === this.activeItem)
      //     this.activeItem = null;
      //   else
      //     this.activeItem = item;
      // }
      // if (!item.items) {
      //   this.onLeafClick(item);
      // }
      // if (item.to && navigate) {
      //   navigate(event);
      // }
    },
    onLeafClick(item) {
      this.activeItem = null;
      this.$emit('leaf-click', item.actionType);
    },
    // onEnter() {
    //   this.position();
    // },
    // position() {
    //   const parentItem = this.$refs.container.parentElement;
    //   const containerOffset = DomHandler.getOffset(this.$refs.container.parentElement)
    //   const viewport = DomHandler.getViewport();
    //   const sublistWidth = this.$refs.container.offsetParent ? this.$refs.container.offsetWidth : DomHandler.getHiddenElementOuterWidth(this.$refs.container);
    //   const itemOuterWidth = DomHandler.getOuterWidth(parentItem.children[0]);
    //   this.$refs.container.style.top = '0px';
    //   if ((parseInt(containerOffset.left, 10) + itemOuterWidth + sublistWidth) > (viewport.width - DomHandler.calculateScrollbarWidth())) {
    //     this.$refs.container.style.left = -1 * sublistWidth + 'px';
    //   }
    //   else {
    //     this.$refs.container.style.left = itemOuterWidth + 'px';
    //   }
    // },
    getItemClass(item) {
      return [
        'p-menuitem', item.class, {
          'p-menuitem-active': this.activeItem === item
        }
      ]
    },
    getLinkClass(item) {
      return ['p-menuitem-link', {'p-disabled': item.disabled}];
    },
    visible(item) {
      return (typeof item.visible === 'function' ? item.visible() : item.visible !== false);
    }
  },
  computed: {
    containerClass() {
      return {'p-submenu-list': !this.root};
    }
  },
  directives: {
    'ripple': Ripple
  }
}
</script>
<style lang="scss" scoped>
.states-title {
  //width: 100%;
  padding: 10px 0 10px 14px;
  font-weight: 500;
}

//.p-menuitem-link {
//  padding: 10px;
//}
</style>