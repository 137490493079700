<template>
    <Teleport to="#order-modal">
        <transition name="p-contextmenu" @enter="onEnter" @leave="onLeave" @after-leave="onAfterLeave">
            <div style="width: 190px" :ref="containerRef" :class="containerClass" v-if="visible" v-bind="$attrs">
                <RequestActionsMenuSub :showSpinner="showSpinner"
                                        :root="true"
                                        :model="model"
                                        @leaf-click="onLeafClick"/>
<!--                                :template="$slots.item" :exact="exact" />-->
            </div>
        </transition>
    </Teleport>
</template>

<script>
import {DomHandler,ZIndexUtils} from 'primevue/utils';
// import ContextMenuSub from './ContextMenuSub.vue';
// import ContextMenuSub from "@/pages/orders/components/ContextMenuSub";
import RequestActionsMenuSub from "@/pages/orders/components/RequestActionsMenuSub";

export default {
    components: {RequestActionsMenuSub},
    name: 'RequestActionsMenu',
    emits: ['leaf-click'],
    inheritAttrs: false,
    props: {
      model: {
          type: Array,
          default: null
      },
      showSpinner: {
        type: Boolean,
        default: false,
      },
      appendTo: {
          type: String,
          default: 'body'
      },
      autoZIndex: {
          type: Boolean,
          default: true
      },
      baseZIndex: {
          type: Number,
          default: 0
      },
      global: {
          type: Boolean,
          default: false
      },
      exact: {
          type: Boolean,
          default: true
      }
    },
    target: null,
    outsideClickListener: null,
    resizeListener: null,
    documentContextMenuListener: null,
    pageX: null,
    pageY: null,
    container: null,
    data() {
        return {
            visible: false
        };
    },
    beforeUnmount() {
        this.unbindResizeListener();
        this.unbindOutsideClickListener();
        this.unbindDocumentContextMenuListener();

        if (this.container && this.autoZIndex) {
            ZIndexUtils.clear(this.container);
        }
        this.container = null;
    },
    mounted() {
        if (this.global) {
            this.bindDocumentContextMenuListener();
        }
      // window.addEventListener('onscroll', this.setTestListener)
    },
    methods: {
      // setTestListener() {
      //   console.log('aaa')
      // },
        itemClick(event) {
            const item = event.item;
            if (item.command) {
                item.command(event);
                event.originalEvent.preventDefault();
            }
            this.hide();
        },
        toggle(event) {
            if (this.visible)
                this.hide();
            else
                this.show(event);
        },
        onLeafClick(actionType) {
            this.hide();
            this.$emit('leaf-click', actionType);
        },
        show(event) {
            // this.pageX = event.pageX;
            // this.pageY = event.pageY;
            this.pageX = event.clientX;
            this.pageY = event.clientY;

            if (this.visible)
                this.position();
            else
                this.visible = true;

            event.stopPropagation();
            event.preventDefault();
        },
        hide() {
            this.visible = false;
        },
        onEnter(el) {
            this.position();
            this.bindOutsideClickListener();
            this.bindResizeListener();

            if (this.autoZIndex) {
                ZIndexUtils.set('menu', el, this.baseZIndex + this.$primevue.config.zIndex.menu);
            }
        },
        onLeave() {
            this.unbindOutsideClickListener();
            this.unbindResizeListener();
        },
        onAfterLeave(el) {
            if (this.autoZIndex) {
                ZIndexUtils.clear(el);
            }
        },
        position() {
            let left = this.pageX + 1;
            let top = this.pageY + 1;
            let width = this.container.offsetParent ? this.container.offsetWidth : DomHandler.getHiddenElementOuterWidth(this.container);
            let height = this.container.offsetParent ? this.container.offsetHeight : DomHandler.getHiddenElementOuterHeight(this.container);
            let viewport = DomHandler.getViewport();

          const orderModal = document.getElementById('order-modal')
          const scrollTop = orderModal.scrollTop;
          top += scrollTop

          // const orderModal = document.getElementById('order-modal')
          // console.log(getComputedStyle(orderModal).scrollTop)
          // console.log(orderModal.getBoundingClientRect())

            // console.log('top: ' + top, 'left: ' + left, 'modal scroll top: ' + orderModal.scrollTop)

            //flip
            // if (left + width - document.body.scrollLeft > viewport.width) {
                left -= width;
            // }

            //flip
            if (top + height - scrollTop > viewport.height) {
                top -= height;
            }

            //fit
            // if (left < window.window.pageXOffset) {
            //     left = window.window.pageXOffset;
            // }

            //fit
            // if (top < window.pageYOffset) {
            //     top = window.pageYOffset;
            // }

            this.container.style.left = left + 'px';
            this.container.style.top = top + 'px';
        },
        bindOutsideClickListener() {
            if (!this.outsideClickListener) {
                this.outsideClickListener = (event) => {
                    if (this.visible && this.container && !this.container.contains(event.target) && !event.ctrlKey) {
                        this.hide();
                    }
                };
                document.addEventListener('click', this.outsideClickListener);
            }
        },
        unbindOutsideClickListener() {
            if (this.outsideClickListener) {
                document.removeEventListener('click', this.outsideClickListener);
                this.outsideClickListener = null;
            }
        },
        bindResizeListener() {
            if (!this.resizeListener) {
                this.resizeListener = () => {
                    if (this.visible) {
                        this.hide();
                    }
                };
                window.addEventListener('resize', this.resizeListener);
            }
        },
        unbindResizeListener() {
            if (this.resizeListener) {
                window.removeEventListener('resize', this.resizeListener);
                this.resizeListener = null;
            }
        },
        bindDocumentContextMenuListener() {
            if (!this.documentContextMenuListener) {
                this.documentContextMenuListener = (event) => {
                    this.show(event);
                };

                document.addEventListener('contextmenu', this.documentContextMenuListener);
            }
        },
        unbindDocumentContextMenuListener() {
            if(this.documentContextMenuListener) {
                document.removeEventListener('contextmenu', this.documentContextMenuListener);
                this.documentContextMenuListener = null;
            }
        },
        containerRef(el) {
            this.container = el;
        }
    },
    computed: {
        containerClass() {
            return ['p-contextmenu p-component', {
                'p-input-filled': this.$primevue.config.inputStyle === 'filled',
                'p-ripple-disabled': this.$primevue.config.ripple === false
            }]
        }
    },
}
</script>

<style>
.p-contextmenu {
    position: absolute;
}

.p-contextmenu ul {
    margin: 0;
    padding: 0;
    list-style: none;
}

.p-contextmenu .p-submenu-list {
    position: absolute;
    min-width: 100%;
    z-index: 1;
}

.p-contextmenu .p-menuitem-link {
    cursor: pointer;
    display: flex;
    align-items: center;
    text-decoration: none;
    overflow: hidden;
    position: relative;
}

.p-contextmenu .p-menuitem-text {
    line-height: 1;
}

.p-contextmenu .p-menuitem {
    position: relative;
}

.p-contextmenu .p-menuitem-link .p-submenu-icon {
    margin-left: auto;
}

.p-contextmenu-enter-from {
    opacity: 0;
}

.p-contextmenu-enter-active {
    transition: opacity 250ms;
}
</style>
