export default [
    // {
    //     actionType: 1,
    //     label: {
    //         en: 'Edit',
    //         et: 'Muuda',
    //         ru: 'Редактировать'
    //     },
    //     icon: 'ti-pencil'
    // },
    {
        actionType: 1,
        label: {
            // en: 'Add',
            en: 'Increase',
            // en: 'Order more',
            // et: 'Lisa',
            et: 'Suurenda',
            // et: 'Tellida juurde',
            // ru: 'Добавить',
            ru: 'Увеличить',
            // ru: 'Заказать еще',
        },
        icon: 'ti-plus'
    },
    {
        actionType: 2,
        label: {
            en: 'Decrease',
            et: 'Vähendada',
            ru: 'Уменьшить'
        },
        icon: 'ti-minus'
    },
    {
        actionType: 3,
        label: {
            en: 'Create a return',
            et: 'Loo tagastus',
            ru: 'Создать возврат'
        },
        icon: 'ti-back-left'
    },
    {
        actionType: 5,
        label: {
            en: 'Cancel return',
            et: 'Tühista tagastus',
            ru: 'Отменить возврат'
        },
        icon: 'ti-back-right'
    },
    {
        actionType: 6,
        label: {
            en: 'Restore',
            et: 'Taasta',
            ru: 'Восстановить'
        },
        icon: 'ti-loop'
        // icon: 'ti-check'
    },
    {
        actionType: 7,
        label: {
            en: 'Delete',
            et: 'Kustuta',
            ru: 'Удалить'
        },
        icon: 'ti-trash'
    },
]